<template>
    <div>
        <!-- Authors Table -->
        <a-row :gutter="24" type="flex">

            <!-- Authors Table Column -->
            <a-col :span="24" class="mb-24">

                <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-if="!loading" :loading="loading">
                   <a-form layout='vertical' :form="form" @submit="submitForm">
                        <a-form-item>
                          <div class="ant-page-header-heading-title">Basic Info</div>

                        </a-form-item>
                            <a-form-item label='Event Title'>
                                <a-input
                                    placeholder="Input a descriptive event name"
                                    v-decorator="[

              'title',
                        { rules: [{ required: true, message: 'Event Title is required' }],
                        initialValue:selectedItem?selectedItem.title:''
              }
            ]"
                                />
                            </a-form-item>


                          <a-form-item label='Collection'>
                            <a-select
                                show-search
                                placeholder="Select a collection"
                                option-filter-prop="children"
                                :filter-option="filterOption"
                                :loading="loadingCategories"
                                v-decorator="[
          'collectionId',
          { rules: [{ required: true, message: 'Please select a collection!' }],
           initialValue:selectedItem?selectedItem.collectionId._id:undefined
           },
        ]"
                            >
                              <a-select-option :disabled="editMode?(cat.status?false:true):false"
                                               v-if="!editMode?(cat.status):true" :label="cat.title" :value="cat._id" v-for="(cat,index) in getAllCategories" :key="index">
                                  <span style="margin-right: 5px;align-items: center" role="img" >
                <a-avatar shape="square" :src="url+cat.image"></a-avatar>
      </span>
                                {{cat.title}}
                              </a-select-option>
                            </a-select>
                            </a-form-item>


                          <a-form-item class="tags" label='Tags (Type in your tag name and hit Enter key)'>
                            <a-select dropdownClassName="tags-dropdown"  mode="tags" style="width: 100%" placeholder="Type in your tag name and hit Enter"
                                      v-decorator="[
          'tags',
          {
                       initialValue:selectedItem.tags

          }
        ]"
                            >

                            </a-select>
                            </a-form-item>


                     <a-form-item label='Organizer'>
                       <a-select
                           show-search
                           allowClear
                           placeholder="Select an organizer"
                           option-filter-prop="children"
                           :filter-option="filterOptionOrganizer"
                           :loading="loadingOrganizers"
                           v-decorator="[
          'organizer',
          {
            rules: [{ required: true, message: 'Please select an organizer!' }],
           initialValue:selectedItem&&selectedItem.organizer?selectedItem.organizer._id:undefined
           },
        ]"
                       >
                         <div slot="dropdownRender" slot-scope="menu">
                           <v-nodes :vnodes="menu" />

                           <a-divider style="margin: 4px 0;" />
                           <div
                               style="padding: 8px 8px; cursor: pointer;"
                               @click="showModal=true"
                           >
                             <a-icon type="plus" /> Add New Organizer
                           </div>
                         </div>
                         <a-select-option :disabled="editMode?(org.status?false:true):false"
                                          v-if="!editMode?(org.status):true" :value="org._id" v-for="(org,index) in getAllOrganizers" :key="index">
                           <span style="margin-right: 5px;align-items: center" role="img" >
                <a-avatar shape="square" :src="url+org.image"></a-avatar>
      </span>
                           {{org.title}}
                         </a-select-option>
                       </a-select>
                     </a-form-item>
                     <FormModal v-if="showModal" :visible="showModal" :selectedItem="selectedOrg" :editMode="false"
                                @formSuccess="formSuccess" @cancel="cancelForm"></FormModal>
                     <a-divider />

                     <a-form-item>
                          <div class="ant-page-header-heading-title">Event Image</div>
                        </a-form-item>
                     <a-form-item>
                       <a-radio-group button-style="solid" v-model="imageType" @change="forceUpdateDom">
                         <a-radio-button value="image">
                           Upload Image
                         </a-radio-button>
                         <a-radio-button value="image_source">
                           Add Image Source
                         </a-radio-button>
                       </a-radio-group>
                     </a-form-item>
                     <a-form-item v-if="imageType=='image'">
                       <a-upload
                           list-type="picture-card"
                           :default-file-list="fileList"
                           @preview="handlePreview"
                           :before-upload="handleFileChange"
                           :multiple="false"
                           :remove="handleFileRemove"
                           v-decorator="[
              `image`,
                        { rules: [{ required: true, message: 'Image is required' }] ,
                                        initialValue:fileList,
                                        }
            ]"
                           accept=".jpeg,.jpg,.gif,.png"
                       >
                         <div v-if="fileList&&fileList.length < 1">
                           <p class="ant-upload-drag-icon" style="margin-bottom: 15px;">
                             <a-icon type="inbox" style="color: #40a9ff;font-size: 40px;"/>
                           </p>
                           <p class="ant-upload-text" style="margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;">
                             Click or drag file to this area to upload
                           </p>
                           <p class="ant-upload-hint" style="color: rgba(0, 0, 0, 0.45);
    font-size: 14px;">
                             Only single file with maximum file size of 5 MB is supported.

                           </p>
                         </div>
                       </a-upload>
                       <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                         <img alt="example" style="width: 100%" :src="previewImage" />
                       </a-modal>
                     </a-form-item>
                     <a-form-item v-if="imageType=='image_source'" label="External Image Url">
                       <a-input
                           placeholder="Input an image source"
                           v-decorator="[
              `sourceUrl`,
                        { rules: [{ required: true, message: 'Image Source is required'}] ,
                        initialValue:selectedItem?selectedItem.image.sourceUrl:'',
              }
            ]"
                       />
                       <div v-if="imageType&&imageType=='image_source'&&form.getFieldValue('sourceUrl')">
                         <img :src="form.getFieldValue('sourceUrl')" alt="Invalid Image URL" class="source-preview"/>
                       </div>
                     </a-form-item>
                     <a-form-model-item label="Image Source Platform Name"
                     >
                       <a-input
                           placeholder="Input an image source platform name like Eventbrite, Facebook"
                           v-decorator="[
              `sourceName`,
                        {
                        initialValue:selectedItem?selectedItem.image.sourceName:'',
              }
            ]"

                       />

                     </a-form-model-item>

                     <a-divider />
                     <div v-if="!$route.params.id">
                     <a-form-item>
                          <div class="ant-page-header-heading-title">Date and Time (GMT+0100 United Kingdom Timezone)</div>

                        </a-form-item>

                        <a-form-item>
                          <a-radio-group default-value="single" button-style="solid" v-model="dateType">
                            <a-radio-button value="single">
                              One Time Event
                            </a-radio-button>
                            <a-radio-button value="recurring">
                              Recurring/Multiple Date Event
                            </a-radio-button>
                          </a-radio-group>
                        </a-form-item>
                        <a-row :gutter="24" type="flex" v-if="dateType=='single'">

                          <!-- Authors Table Column -->
                          <a-col :span="6" >
                            <a-form-item label='Event Start Date & Time'>
                              <a-date-picker
                                  :disabled-date="disabledStartDate"
                                  show-time
                                  format="YYYY-MM-DD HH:mm"
                                  placeholder="Start Date Time"
                                  @openChange="handleStartOpenChange"
                                  v-decorator="[

              'startDate',
                        { rules: [{ required: true, message: 'Start Date is required' }] ,
                                                initialValue:startDate,

              }
            ]"
                              />

                            </a-form-item>
                          </a-col>

                          <a-col :span="6" >
                            <a-form-item label='End Date & Time'>
                              <a-date-picker
                                  :disabled-date="disabledEndDate"
                                  show-time
                                  format="YYYY-MM-DD HH:mm"
                                  placeholder="End Date Time"
                                  :open="endOpen"
                                  @openChange="handleEndOpenChange"
                                  v-decorator="[

              'endDate',
                        { rules: [{ required: true, message: 'End Date is required' }],
                        initialValue:endDate,
              }
            ]"
                              />
                            </a-form-item>
                          </a-col>
                        </a-row>
                       <a-row :gutter="24" type="flex" v-if="dateType=='single'">

                         <!-- Authors Table Column -->
                         <a-col :span="24">
                           <a-alert message="You can change this preference anytime and also add multiple schedules in another step." type="info" show-icon />

                         </a-col>
                       </a-row>
                     <a-row :gutter="24" type="flex" v-if="dateType=='recurring'">

                       <!-- Authors Table Column -->
                       <a-col :span="24" >
                         <a-alert message="Schedule for recurring/multiple date event can be set in another step." type="info" show-icon />

                       </a-col>
                     </a-row>

                     <div v-if="dateType=='single'">
                       <a-divider></a-divider>

                       <a-form-item>
                         <div class="ant-page-header-heading-title">Event Type </div>

                       </a-form-item>

                       <a-form-item>
                         <a-radio-group default-value="physical" button-style="solid" v-model="locationType">
                           <a-radio-button value="physical">
                             Physical Location
                           </a-radio-button>
                           <a-radio-button value="online">
                             Online Event
                           </a-radio-button>
                           <a-radio-button value="not_announced">
                             Not announced yet
                           </a-radio-button>
                         </a-radio-group>
                       </a-form-item>
                       <a-row :gutter="24" type="flex" v-if="locationType=='physical'">

                         <!-- Authors Table Column -->
                         <a-col :span="12" >
                           <a-form-item label='Venue Name'>
                             <a-input
                                 placeholder="Input a venue name"
                                 v-decorator="[

              'venue',
                        { rules: [{ required: true, message: 'Venue Name is required' }] ,
              }
            ]"
                             />
                           </a-form-item>
                         </a-col>
                         <a-col :span="12" >
                           <a-form-item label='Street Address'>
                             <a-input
                                 placeholder="Input a descriptive address"
                                 v-decorator="[

              'streetAddress',
                        { rules: [{ required: true, message: 'Street Address is required' }] ,
              }
            ]"
                             />
                           </a-form-item>
                         </a-col>

                         <a-col :span="24" >
                           <a-form-item label='Post Code'>
                             <a-select
                                 show-search
                                 placeholder="Input Post Code"
                                 :default-active-first-option="false"
                                 :show-arrow="false"
                                 :filter-option="false"
                                 :not-found-content="fetching ? undefined : null"
                                 @search="fetchPostCode"
                                 @change="handleChange"
                                 v-decorator="[

              'post_code_with_address',
                        { rules: [{ required: true, message: 'Post Code is required' }],
                        // initialValue:post_code_with_address?post_code_with_address:'',
                         },

            ]"
                             >
                               <a-spin v-if="fetching" slot="notFoundContent" size="small" />
<!--                               <a-select-option value="">-->
<!--                                 Input Post Code-->
<!--                               </a-select-option>-->
                               <a-select-option v-for="d in data" :key="d.value">
                                 {{ d.text }}
                               </a-select-option>
                             </a-select>
                           </a-form-item>
                         </a-col>
                       </a-row>

                       <a-row :gutter="24" type="flex" v-if="locationType=='online'">

                         <!-- Authors Table Column -->
                         <a-col :span="24">
                           <a-alert message="Online events do not include any physical location information. You will get to add online links in another step." type="info" show-icon />

                         </a-col>
                       </a-row>
                       <a-row :gutter="24" type="flex" v-if="locationType=='not_announced'">

                         <!-- Authors Table Column -->
                         <a-col :span="24">
                           <a-alert message="You can select this option if the type of event or venue of event has not been decided yet. However, you can edit later in another step." type="info" show-icon />

                         </a-col>
                       </a-row>
                     </div>
                     <a-divider></a-divider>
                     </div>
                     <a-form-item :wrapper-col="{ span: 24 }" :style="{ textAlign: 'right' }">
                       <a-space size="small">
                       <a-button type="primary" html-type="submit" :loading="confirmLoading">
                         {{ footerBtnTitle }}
                       </a-button>
                         <router-link :to="{name:'Events'}">
                         <a-button type="danger" :loading="confirmLoading">
                           Cancel
                         </a-button>
                         </router-link>
                       </a-space>
                     </a-form-item>

                        </a-form>


                </a-card>
              <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
                <a-row :gutter="[24, 24]">
                  <a-col :span="24" >
                    <a-skeleton active />
                    <a-skeleton active />
                    <a-skeleton active />
                    <a-skeleton active />
                  </a-col>
                </a-row>
              </a-card>

            </a-col>

        </a-row>
    </div>
</template>

<script>

import debounce from 'lodash/debounce';
import moment from 'moment';
import FormModal from "../organizer/FormModal";


export default {
  components: {FormModal,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },},

  data() {
      this.lastFetchId = 0;
      this.fetchPostCode = debounce(this.fetchPostCode, 800);

      return {
          loadingCategories:false,
          loadingOrganizers:false,
          data: [],
          value: [],
          fetching: false,
        lng:'',
        lat:'',
        district:'',
        country:'',
        ward:'',
        ccg:'',
        locationType:'physical',
        post_code_with_address:'',
        startDate:'',
        endDate:'',
        startValue: null,
        endValue: null,
        endOpen: false,
        dateType:'',
        id:'',
            url: process.env.VUE_APP_SERVER_URL,

            loading: false,
            fileList: [],
            confirmLoading: false,
            form: this.$form.createForm(this),
        editMode:false,
        previewImage: '',
        type:'image',
        previewVisible:false,
        showModal:false,
        selectedOrg:{},
        imageType:'',
        imageUrl:'',

        };
    },
    computed: {
      footerBtnTitle() {
            return this.editMode ? 'Update Event' : 'Create Event'
        },
      getAllCategories(){
          return this.$store.getters.getAllCategories
      },
      selectedItem(){
          return this.$store.getters.selectedEvent
      },
      getAllOrganizers(){
          return this.$store.getters.getAllOrganizers
      }
        },
  beforeCreate() {
    if (!this.$route.params.id)
      this.$store.commit('RESET_SELECTED_EVENT')
  },
    mounted() {
        this.allFetchCategories();
        this.allFetchOrganizers();
        if (this.$route.params.id){
          this.editMode=true
        this.eventInfo()
        }
        else {
          this.locationType='physical'
          this.dateType='single'
          this.imageType='image'
        }
    },

  methods: {
    forceUpdateDom() {
      setTimeout(()=>{
        this.$forceUpdate()
      },10)
    },
      formSuccess(){
        this.allFetchOrganizers();
        this.showModal=false
      },
      cancelForm(){
        this.showModal=false
      },
      eventInfo(){
        this.loading=true
        this.$store.dispatch("FETCH_EVENT",{id:this.$route.params.id})
            .then((res)=>{
              let data =res.data
              this.id=data._id
              // this.dateType=data.dateType
              this.imageType=data.image.type
              // if (this.dateType=='single'){
              //   this.startDate=moment(data.singleSchedule.startDate)
              //   this.endDate=moment(data.singleSchedule.endDate)
              //   this.locationType=data.singleSchedule.locationType
              //   if (this.locationType=='physical') {
              //     this.lng=data.singleSchedule.lng
              //     this.lat=data.singleSchedule.lat
              //     this.country=data.singleSchedule.country
              //     this.district=data.singleSchedule.district
              //     this.ward=data.singleSchedule.ward
              //     this.ccg=data.singleSchedule.ccg
              //     this.post_code=data.singleSchedule.post_code
              //     this.post_code_with_address = this.post_code + ', ' + this.district + ', ' + this.country
              //   }
              // }
              //
              //
              if (data.image.type=='image'){
                this.fileList= [
                  {
                    uid: '-1',
                    name: data.image.uploadPath,
                    status: 'done',
                    url: this.url+data.image.uploadPath,
                    thumbnail: this.url+data.image.uploadPath,
                  },
                ]
              }
              setTimeout(()=>{
                this.$forceUpdate()
              },10)
            })
        .finally(()=>{
          this.loading=false
        })
      },
        submitForm(e) {
            e.preventDefault();
          if (this.confirmLoading)
            return
            this.form.validateFields((err, values) => {
                if (!err) {
                  values.id=this.id
                  values.lng=this.lng
                  values.lat=this.lat
                  values.country=this.country
                  values.district=this.district
                  values.ward=this.ward
                  values.ccg=this.ccg
                  values.post_code=this.post_code
                  values.locationType=this.locationType
                  values.dateType=this.dateType
                  values.imageType=this.imageType
                  const formData = new FormData();
                  formData.append('image', values.image?values.image.file:'');
                  Object.entries(values).forEach((entry) => {
                    const [key, value] = entry;
                    if(key=='tags')
                      formData.append(key, JSON.stringify(value));
                   else if(key!='image')
                    formData.append(key, value);

                  });

                  this.confirmLoading = true
                    if (this.editMode){
                        this.$store.dispatch("UPDATE_EVENT", {id:this.id,form:formData})
                            .then((res) => {
                                this.$message.success(res.data.message);
                              this.$store.commit('CHANGE_DATE_TYPE',values.dateType)
                              // if (values.dateType=='recurring') {
                                this.$emit('changeTab', '6')
                              // }
                              // else
                              //   this.$emit('changeTab','2')
                            })
                            .catch((err) => {
                                // this.$message.error(err.response.data.message);
                            })
                            .finally(() => {
                                this.confirmLoading = false
                            });
                    }
                    else {
                        this.$store.dispatch("CREATE_EVENT", formData)
                            .then((res) => {
                                this.form.resetFields()
                                this.$message.success(res.data.message);
                                // if (values.dateType=='recurring')
                                this.$router.push({name:'Edit Event | Schedules',params:{id:res.data.id}})
                              // else
                              //   this.$router.push({name:'Edit Event | Descriptions',params:{id:res.data.id}})
                            })
                            .finally(() => {
                                this.confirmLoading = false
                            });

                    }

                }
            });
        },
        handleRemove(file) {
            const index = this.fileList.indexOf(file);
            const newFileList = this.fileList.slice();
            newFileList.splice(index, 1);
            this.fileList = newFileList;
        },
        beforeUpload(file) {
            if (this.fileList.length < 1)
                this.fileList = [...this.fileList, file];

            return false;
        },

      allFetchCategories(){
          this.loadingCategories=true
        this.$store.dispatch("FETCH_ALL_COLLECTIONS")
        .then(()=>{
          this.loadingCategories=false
        })
      },
      allFetchOrganizers(){
          this.loadingOrganizers=true
        this.$store.dispatch("FETCH_ALL_ORGANIZERS")
        .then(()=>{
          this.loadingOrganizers=false
        })
      },
      filterOption(input, option) {
        return (
            option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      filterOptionOrganizer(input, option) {
        return (
            option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      fetchPostCode(value) {
          if (value){
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.data = [];
        this.fetching = true;
        fetch('https://api.postcodes.io/postcodes/' + value + '/autocomplete')
            .then(response => response.json())
            .then(body => {
              if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
              }
              if (!body.result) {
                this.data = [{text:'No Post Code Found',value:''}];
                this.fetching = false;
                return;
              }
              const data = body.result.map(item => ({
                text: item,
                value: item,
              }));
              this.data = data;
              this.fetching = false;
            });
      }
          else {
            this.lng=this.lat=this.district=this.country=this.ward=this.ccg=this.post_code=''
          }
      },
      handleChange(value) {
        fetch('https://api.postcodes.io/postcodes/' + value)
            .then(response => response.json())
            .then(body => {
              this.lng=body.result.longitude
              this.lat=body.result.latitude
              this.country=body.result.country
              this.district=body.result.admin_district
              this.ward=body.result.admin_ward
              this.ccg=body.result.ccg
              this.post_code=value
              const addr=value+', '+this.district+', '+this.country
              this.form.setFieldsValue({
                post_code_with_address: addr,
              });
              Object.assign(this, {
                data: [],
                fetching: false,
              });
            })

      },
      disabledStartDate(current) {
        return current && current < moment().startOf('day');

        // const endValue = this.endValue;
        // if (!startValue || !endValue) {
        //   return false
        // }
        // return startValue.valueOf() > endValue.valueOf();
      },
      disabledEndDate(endValue) {
        const startValue = this.form.getFieldValue('startDate');
        if (!endValue || !startValue) {
          return false;
        }
        return startValue.valueOf() >= endValue.valueOf();
      },
      handleStartOpenChange(open) {
        if (!open) {
          this.endOpen = true;
        }
      },
      handleEndOpenChange(open) {
        this.endOpen = open;
      },
      handleCancel() {
        this.previewVisible = false;
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
      handleFileChange(file) {
        if (this.fileList.length < 1)
          this.fileList = [...this.fileList, file];

        return false;
      },
      handleFileRemove(file) {
        this.fileList = [];
      },

    },
};
</script>
<style>
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select{
  width: 50%;
}
.ant-calendar-time-picker-select:last-child{
  display: none;
}

.ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
}

</style>
